<template>
  <login-form />
</template>

<script>
export default {
  components: {
    loginForm: () => import('@/components/auth/LoginForm.vue'),
  },
}
</script>
